import {Modal} from 'bootstrap';
import {useEffect, useRef} from "preact/hooks";
import {render} from "preact";

const App = () => {
    useEffect(() => {
        new Modal(document.getElementById('login-modal'), {backdrop: false, keyboard: false}).show()
    })
    const user = useRef(null)
    const password = useRef(null)

    const login = () => {
        const username = user.current.value;
        fetch("/yi/login", {method: "POST", headers: {"Content-Type": "application/json"},
             body: JSON.stringify({username, password: password.current.value})})
            .then(res => location.href = res.url )
    }

    return (<div className="d-flex flex-column main-box position-relative">
        <img src="/yi/img/login/login-bg.webp" alt="" style={{ display: "none" }}
             onLoad={() => document.body.style.backgroundImage = "url('/yi/img/login/login-bg.webp')"}/>

        <div className="flex-grow-1" >
            <div className="modal" tabIndex="-1" id="login-modal">
                <div className="modal-dialog modal-dialog-centered" >
                    <div className="modal-content" style={{borderRadius: "0", border: "none", background: "linear-gradient(to bottom,rgba(255,255,255,1),rgba(255,255,255,0.7))"}}>
                        <div className="modal-body">
                            <div className="d-flex flex-column align-items-center">
                                <div className="d-flex align-items-center mb-4 mt-2">
                                    <img alt="milkman logo" width="45" height="45" src="/yi/img/ok.png" style={{marginTop: "-2px"}}/>
                                    <span className="ms-2 text-nowrap user-select-none pt-1" style={{fontSize: "30px", color: "#454545"}}>{"Your\u2006Insight"}</span>
                                </div>
                            </div>
                            <form className="mx-3">
                                <div className="mb-3">
                                    <label htmlFor="username" className="form-label fst-normal ms-2">User (email)</label>
                                    <input ref={user} type="text" className="form-control" id="username"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label fst-normal ms-2">Password</label>
                                    <input ref={password} type="password" className="form-control" id="password"/>
                                </div>
                            </form>
                            <div className="d-flex pt-4 mb-3">
                                <span className="flex-grow-1"/>
                                <button type="button"  style={{width: "150px", fontSize: "16px", marginTop: "1px"}} className="btn btn-primary me-3" onClick={login}>Log In</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

render(<App/>, document.getElementById('app'))